// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

.animated-row {
  animation: fadeInFromTop 1s ease forwards; }
`, "",{"version":3,"sources":["webpack://./src/styles/AnimationDelay.scss"],"names":[],"mappings":"AAAA;EACI;IACE,UAAU;IACV,4BAA4B,EAAA;EAE9B;IACE,UAAU;IACV,wBAAwB,EAAA,EAAA;;AAI5B;EACE,yCAAyC,EAAA","sourcesContent":["@keyframes fadeInFromTop {\n    from {\n      opacity: 0;\n      transform: translateY(-20px);\n    }\n    to {\n      opacity: 1;\n      transform: translateY(0);\n    }\n  }\n  \n  .animated-row {\n    animation: fadeInFromTop 1s ease forwards;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
