// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/assets/d-bg_kapo.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/assets/bg-kap-gore.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: top; }
  @media screen and (max-width: 1024px) {
    .App {
      background-size: contain;
      background-repeat: no-repeat; } }
  @media screen and (max-width: 600px) {
    .App {
      background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
      background-size: auto;
      background-repeat: no-repeat; } }
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,yDAAuD;EAEvD,4BAA4B;EAC5B,wBAAwB,EAAA;EAGxB;IAVJ;MAWM,wBAAwB;MACxB,4BAA4B,EAAA,EAS/B;EALC;IAhBJ;MAiBM,yDAAyD;MACzD,qBAAqB;MACrB,4BAA4B,EAAA,EAE/B","sourcesContent":[".App {\n    width: 100%;\n    max-width: 1920px;\n    margin: 0 auto;\n    background-image: url(\"../public/assets/d-bg_kapo.svg\");\n    // background-size: cover;\n    background-repeat: no-repeat;\n    background-position: top;\n  \n    \n    @media screen and (max-width: 1024px) {\n      background-size: contain;\n      background-repeat: no-repeat;\n    }\n  \n  \n    @media screen and (max-width: 600px) {\n      background-image: url(\"../public/assets/bg-kap-gore.svg\");\n      background-size: auto;\n      background-repeat: no-repeat;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
