// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/assets/points_bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --linear-gradient: linear-gradient(
    90deg,
    #bd8b2e 22.07%,
    #e8d476 36.51%,
    #bf9b3a 49.98%,
    #e8d370 68.27%,
    #dfc667 87.52%,
    #edd97d 118.32%
  ); }

.points-section-container {
  width: 100%;
  max-width: 1920px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: contain;
  background-repeat: repeat;
  background-position: bottom; }
  .points-section-container .total-points {
    border: 1px solid black;
    background: var(--linear-gradient); }
  @media screen and (max-width: 600px) {
    .points-section-container {
      background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
      background-size: contain;
      background-repeat: no-repeat !important; } }
`, "",{"version":3,"sources":["webpack://./src/styles/PointsSection.scss"],"names":[],"mappings":"AAAA;EACE;;;;;;;;GAAkB,EAAA;;AAWlB;EACE,WAAW;EACX,iBAAiB;EACjB,yDAA0D;EAC1D,wBAAwB;EACxB,yBAAyB;EACzB,2BAA2B,EAAA;EAN7B;IASE,uBAAuB;IACvB,kCAAkC,EAAA;EAGpC;IAbA;MAeE,yDAA0D;MAC1D,wBAAwB;MACxB,uCAAuC,EAAA,EAG1C","sourcesContent":[":root {\n  --linear-gradient: linear-gradient(\n    90deg,\n    #bd8b2e 22.07%,\n    #e8d476 36.51%,\n    #bf9b3a 49.98%,\n    #e8d370 68.27%,\n    #dfc667 87.52%,\n    #edd97d 118.32%\n  );\n}\n\n  .points-section-container {\n    width: 100%;\n    max-width: 1920px;\n    background-image: url(\"../../public/assets/points_bg.svg\");\n    background-size: contain;\n    background-repeat: repeat;\n    background-position: bottom;\n\n  .total-points {\n    border: 1px solid black;\n    background: var(--linear-gradient);\n  }\n\n  @media screen and (max-width: 600px) {\n\n    background-image: url(\"../../public/assets/points_bg.svg\");\n    background-size: contain;\n    background-repeat: no-repeat !important;\n  \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
