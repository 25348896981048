// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ol-viewport {
  border-radius: 8px; }
  .ol-viewport .ol-zoom {
    font-size: 25px; }
  .ol-viewport .ol-rotate {
    font-size: 25px; }
`, "",{"version":3,"sources":["webpack://./src/styles/LockerMapLocations.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB,EAAA;EADtB;IAIQ,eAAe,EAAA;EAJvB;IAOQ,eAAe,EAAA","sourcesContent":[".ol-viewport{\n    border-radius: 8px;\n\n    .ol-zoom{\n        font-size: 25px;\n    }\n    .ol-rotate{\n        font-size: 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
