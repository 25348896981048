// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/assets/bg3.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1024px) {
  .home-container {
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: bottom; } }

@media screen and (max-width: 600px) {
  .home-container {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom; } }
`, "",{"version":3,"sources":["webpack://./src/styles/HomeSection.scss"],"names":[],"mappings":"AACI;EADJ;IAGQ,wBAAwB;IACxB,4BAA4B;IAC5B,2BAA2B,EAAA,EAUlC;;AANK;EATN;IAUQ,yDAAoD;IACpD,wBAAwB;IACxB,4BAA4B;IAC5B,2BAA2B,EAAA,EAElC","sourcesContent":[".home-container {\n    @media screen and (max-width: 1024px) {\n        \n        background-size: inherit;\n        background-repeat: no-repeat;\n        background-position: bottom;\n      }\n    \n    \n      @media screen and (max-width: 600px) {\n        background-image: url(\"../../public/assets/bg3.svg\");\n        background-size: contain;\n        background-repeat: no-repeat;\n        background-position: bottom;\n      }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
