// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-container .report-line {
  background: linear-gradient(90deg, #bd8b2e 22.07%, #e8d476 36.51%, #bf9b3a 49.98%, #e8d370 68.27%, #dfc667 87.52%, #edd97d 118.32%); }

.report-container input[type="checkbox"] {
  appearance: none;
  background-color: white;
  font: inherit;
  color: black;
  width: 30px;
  height: 30px;
  border: 1px solid transparent;
  transform: translateY(0.2em);
  display: grid;
  place-content: center;
  flex-shrink: 0; }

.report-container input[type="checkbox"]::before {
  content: "";
  width: 1em;
  height: 0.9em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background-color: black; }

.report-container input[type="checkbox"]:checked::before {
  transform: scale(1); }

.report-container textarea::placeholder {
  color: #AAAAAA;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 13px 0 0 15px; }
`, "",{"version":3,"sources":["webpack://./src/styles/Report.scss"],"names":[],"mappings":"AAAA;EAEQ,mIAQD,EAAA;;AAVP;EAeQ,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;EACb,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,4BAA4B;EAE5B,aAAa;EACb,qBAAqB;EACrB,cAAc,EAAA;;AA1BtB;EA8BQ,WAAW;EACX,UAAU;EACV,aAAa;EACb,uEAAuE;EACvE,mBAAmB;EACnB,6BAA6B;EAC7B,uCAAuC;EAEvC,uBAAuB,EAAA;;AAtC/B;EA0CQ,mBAAmB,EAAA;;AA1C3B;EA+CQ,cAAc;EACd,gCAAgC;EAChC,eAAe;EACf,sBAAsB,EAAA","sourcesContent":[".report-container{\n    .report-line{\n        background: linear-gradient(\n        90deg,\n        #bd8b2e 22.07%,\n        #e8d476 36.51%,\n        #bf9b3a 49.98%,\n        #e8d370 68.27%,\n        #dfc667 87.52%,\n        #edd97d 118.32%\n      );\n    }\n    \n    input[type=\"checkbox\"] {\n        -webkit-appearance: none;\n        appearance: none;\n        background-color: white;\n        font: inherit;\n        color: black;\n        width: 30px;\n        height: 30px;\n        border: 1px solid transparent;\n        transform: translateY(0.2em);\n    \n        display: grid;\n        place-content: center;\n        flex-shrink: 0;\n    }\n    \n    input[type=\"checkbox\"]::before {\n        content: \"\";\n        width: 1em;\n        height: 0.9em;\n        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);\n        transform: scale(0);\n        transform-origin: bottom left;\n        transition: 120ms transform ease-in-out;\n    \n        background-color: black;\n    }\n    \n    input[type=\"checkbox\"]:checked::before {\n        transform: scale(1);\n    }\n    \n    \n    textarea::placeholder {\n        color: #AAAAAA; \n        font-family: 'Inter', sans-serif;\n        font-size: 14px;\n        padding: 13px 0 0 15px;\n      }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
