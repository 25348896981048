// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --linear-gradient: linear-gradient(
    90deg,
    #bd8b2e 22.07%,
    #e8d476 36.51%,
    #bf9b3a 49.98%,
    #e8d370 68.27%,
    #dfc667 87.52%,
    #edd97d 118.32%
  );
  --radial-gradient: radial-gradient(
    48.91% 48.91% at 49.99% 50%,
    #ee382c 0%,
    #ea362c 29%,
    #e1332c 50%,
    #d02d2d 70%,
    #b9252e 88%,
    #a61e2f 100%
  );
  --linear-gradient-disabled: linear-gradient(
    90deg,
    #c6c6c6 0%,
    #e4e4e4 34%,
    #ffffff 71%,
    #bbbbbb 99%
  ); }

.button {
  background: var(--linear-gradient); }
  .button:hover {
    background: var(--radial-gradient);
    color: white; }

.border-btn {
  border: 1px solid transparent;
  background: linear-gradient(white, white) padding-box, var(--linear-gradient) border-box; }
  .border-btn:hover {
    border: 1px solid transparent;
    background: linear-gradient(white, white) padding-box, var(--radial-gradient) border-box; }

.disabled-btn {
  background: var(--linear-gradient-disabled);
  color: #AAAAAA; }
`, "",{"version":3,"sources":["webpack://./src/styles/Button.scss"],"names":[],"mappings":"AAAA;EACE;;;;;;;;GAAkB;EASlB;;;;;;;;GAAkB;EASlB;;;;;;GAA2B,EAAA;;AAS7B;EACE,kCAAkC,EAAA;EADpC;IAII,kCAAkC;IAClC,YAAY,EAAA;;AAIhB;EACE,6BAA6B;EAC7B,wFACmC,EAAA;EAHrC;IAMI,6BAA6B;IAC7B,wFACmC,EAAA;;AAIvC;EACE,2CAA2C;EAC3C,cAAc,EAAA","sourcesContent":[":root {\n  --linear-gradient: linear-gradient(\n    90deg,\n    #bd8b2e 22.07%,\n    #e8d476 36.51%,\n    #bf9b3a 49.98%,\n    #e8d370 68.27%,\n    #dfc667 87.52%,\n    #edd97d 118.32%\n  );\n  --radial-gradient: radial-gradient(\n    48.91% 48.91% at 49.99% 50%,\n    #ee382c 0%,\n    #ea362c 29%,\n    #e1332c 50%,\n    #d02d2d 70%,\n    #b9252e 88%,\n    #a61e2f 100%\n  );\n  --linear-gradient-disabled: linear-gradient(\n    90deg,\n    #c6c6c6 0%,\n    #e4e4e4 34%,\n    #ffffff 71%,\n    #bbbbbb 99%\n  );\n}\n\n.button {\n  background: var(--linear-gradient);\n\n  &:hover {\n    background: var(--radial-gradient);\n    color: white;\n  }\n}\n\n.border-btn {\n  border: 1px solid transparent;\n  background: linear-gradient(white, white) padding-box,\n    var(--linear-gradient) border-box;\n\n  &:hover {\n    border: 1px solid transparent;\n    background: linear-gradient(white, white) padding-box,\n      var(--radial-gradient) border-box;\n  }\n}\n\n.disabled-btn {\n  background: var(--linear-gradient-disabled);\n  color: #AAAAAA;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
