// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line {
  background: linear-gradient(90deg, #bd8b2e 22.07%, #e8d476 36.51%, #bf9b3a 49.98%, #e8d370 68.27%, #dfc667 87.52%, #edd97d 118.32%); }

.line_2 {
  background-color: #383737;
  height: 4px; }
`, "",{"version":3,"sources":["webpack://./src/styles/Line.scss"],"names":[],"mappings":"AAAA;EACI,mIAQD,EAAA;;AAGH;EACC,yBAAyB;EACzB,WAAW,EAAA","sourcesContent":[".line{\n    background: linear-gradient(\n    90deg,\n    #bd8b2e 22.07%,\n    #e8d476 36.51%,\n    #bf9b3a 49.98%,\n    #e8d370 68.27%,\n    #dfc667 87.52%,\n    #edd97d 118.32%\n  );\n}\n\n.line_2{\n background-color: #383737;\n height: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
