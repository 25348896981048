// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=date]::-webkit-datetime-edit-text {
  -webkit-appearance: none; }

input[type=date]::-webkit-datetime-edit-month-field {
  -webkit-appearance: none; }

input[type=date]::-webkit-datetime-edit-day-field {
  -webkit-appearance: none; }

input[type=date]::-webkit-datetime-edit-year-field {
  -webkit-appearance: none; }

input[type="checkbox"] {
  appearance: none;
  background-color: white;
  font: inherit;
  color: black;
  border: 1px solid #EBEBEB;
  transform: translateY(0.2em);
  display: grid;
  place-content: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px; }
  input[type="checkbox"]::before {
    content: "";
    width: 1em;
    height: 0.9em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: black; }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
    z-index: 999; }

textarea::placeholder {
  color: #AAAAAA;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 13px 0 0 15px; }
`, "",{"version":3,"sources":["webpack://./src/styles/Input.scss"],"names":[],"mappings":"AAAA;EACI,wBAAwB,EAAA;;AAG5B;EACI,wBAAwB,EAAA;;AAG5B;EACI,wBAAwB,EAAA;;AAG5B;EACI,wBAAwB,EAAA;;AAMxB;EAEE,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;EACb,YAAY;EAGZ,yBAAyB;EACzB,4BAA4B;EAC5B,aAAa;EACb,qBAAqB;EACrB,cAAc;EACd,WAAW;EACX,YAAY,EAAA;EAdd;IAiBI,WAAW;IACX,UAAU;IACV,aAAa;IACb,uEAAuE;IACvE,mBAAmB;IACnB,6BAA6B;IAC7B,uCAAuC;IACvC,uBAAuB,EAAA;EAxB3B;IA4BI,mBAAmB;IACnB,YAAY,EAAA;;AAKlB;EACE,cAAc;EACd,gCAAgC;EAChC,eAAe;EACf,sBAAsB,EAAA","sourcesContent":["input[type=date]::-webkit-datetime-edit-text {\n    -webkit-appearance: none;\n  \n}\ninput[type=date]::-webkit-datetime-edit-month-field{\n    -webkit-appearance: none;\n   \n}\ninput[type=date]::-webkit-datetime-edit-day-field {\n    -webkit-appearance: none;\n   \n}\ninput[type=date]::-webkit-datetime-edit-year-field {\n    -webkit-appearance: none;\n   \n}\n\n\n\n    input[type=\"checkbox\"] {\n      -webkit-appearance: none;\n      appearance: none;\n      background-color: white;\n      font: inherit;\n      color: black;\n      width: 30px;\n      height: 30px;\n      border: 1px solid #EBEBEB;\n      transform: translateY(0.2em);\n      display: grid;\n      place-content: center;\n      flex-shrink: 0;\n      width: 30px;\n      height: 30px;\n  \n      &::before {\n        content: \"\";\n        width: 1em;\n        height: 0.9em;\n        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);\n        transform: scale(0);\n        transform-origin: bottom left;\n        transition: 120ms transform ease-in-out;\n        background-color: black;\n      }\n  \n      &:checked::before {\n        transform: scale(1);\n        z-index: 999;\n      }\n    }\n\n  \n  textarea::placeholder {\n    color: #AAAAAA; \n    font-family: 'Inter', sans-serif;\n    font-size: 14px;\n    padding: 13px 0 0 15px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
