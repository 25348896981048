// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/assets/d-bg_participations.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/assets/bg2.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-container {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: auto;
  background-repeat: no-repeat;
  margin: 0 auto; }
  @media screen and (max-width: 600px) {
    .steps-container {
      background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
      background-size: cover;
      background-repeat: no-repeat; } }
`, "",{"version":3,"sources":["webpack://./src/styles/ParticipationSteps.scss"],"names":[],"mappings":"AAAA;EACI,yDAAoE;EACpE,qBAAqB;EACrB,4BAA4B;EAC5B,cAAc,EAAA;EAEd;IANJ;MAOQ,yDAAoD;MACpD,sBAAsB;MACtB,4BAA4B,EAAA,EAEnC","sourcesContent":[".steps-container {\n    background-image: url(\"../../public/assets/d-bg_participations.svg\");\n    background-size: auto;\n    background-repeat: no-repeat;\n    margin: 0 auto;\n\n    @media screen and (max-width: 600px) {\n        background-image: url(\"../../public/assets/bg2.svg\");\n        background-size: cover;\n        background-repeat: no-repeat;\n      }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
