// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/assets/d-bg_footer.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/assets/footer_bg2.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-main {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  width: 100%; }
  @media screen and (max-width: 600px) {
    .footer-main {
      background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
      background-repeat: no-repeat;
      width: 100%;
      padding-bottom: 50px;
      height: 100%; } }
  .footer-main .copyLink {
    color: #DFC667; }
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.scss"],"names":[],"mappings":"AAAA;EACI,yDAA4D;EAC5D,sBAAsB;EACtB,WAAW,EAAA;EAGX;IANJ;MAOQ,yDAA2D;MAE3D,4BAA4B;MAC5B,WAAW;MACX,oBAAoB;MACpB,YAAY,EAAA,EAMnB;EAlBD;IAgBQ,cAAc,EAAA","sourcesContent":[".footer-main {\n    background-image: url(\"../../public/assets/d-bg_footer.svg\");\n    background-size: cover;\n    width: 100%;\n    // height: 850px;\n\n    @media screen and (max-width: 600px) {\n        background-image: url(\"../../public/assets/footer_bg2.svg\");\n        // background-size: auto;\n        background-repeat: no-repeat;\n        width: 100%;\n        padding-bottom: 50px;\n        height: 100%;\n    }\n\n    .copyLink{\n        color: #DFC667;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
