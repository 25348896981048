// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

.skeleton {
  width: 100%;
  height: 40px;
  /* Promijenjena visina zbog animacije */
  background: #e1e1e1;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  animation: fadeInFromTop 0.5s ease forwards; }

.skeleton::before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite; }

@keyframes load {
  from {
    left: -150px; }
  to {
    left: 100%; } }
`, "",{"version":3,"sources":["webpack://./src/styles/LoaderSkeleton.scss"],"names":[],"mappings":"AAEA;EACE;IACE,UAAU;IACV,4BAA4B,EAAA;EAE9B;IACE,UAAU;IACV,wBAAwB,EAAA,EAAA;;AAI5B;EACE,WAAW;EACX,YAAY;EAAE,uCAAA;EACd,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,2CAA2C,EAAA;;AAG7C;EACE,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,MAAM;EACN,YAAY;EACZ,YAAY;EACZ,oFAAoF;EACpF,wDAAwD,EAAA;;AAG1D;EACE;IACE,YAAY,EAAA;EAEd;IACE,UAAU,EAAA,EAAA","sourcesContent":["// LoaderSkeleton.scss\n\n@keyframes fadeInFromTop {\n  from {\n    opacity: 0;\n    transform: translateY(-20px);\n  }\n  to {\n    opacity: 1;\n    transform: translateY(0);\n  }\n}\n\n.skeleton {\n  width: 100%;\n  height: 40px; /* Promijenjena visina zbog animacije */\n  background: #e1e1e1;\n  border-radius: 4px;\n  position: relative;\n  overflow: hidden;\n  animation: fadeInFromTop 0.5s ease forwards;\n}\n\n.skeleton::before {\n  content: \"\";\n  display: block;\n  position: absolute;\n  left: -150px;\n  top: 0;\n  height: 100%;\n  width: 150px;\n  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);\n  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;\n}\n\n@keyframes load {\n  from {\n    left: -150px;\n  }\n  to {\n    left: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
