// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-container {
  width: 100%;
  max-width: 1920px; }

.hamburger-react {
  position: fixed;
  top: 0;
  right: 0; }
`, "",{"version":3,"sources":["webpack://./src/styles/Nav.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB,EAAA;;AAGnB;EACE,eAAe;EACf,MAAM;EACN,QAAQ,EAAA","sourcesContent":[".nav-container {\n  width: 100%;\n  max-width: 1920px;\n}\n\n.hamburger-react {\n  position: fixed;\n  top: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
