// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/assets/d-val.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-main {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  margin-top: 20px; }
  @media screen and (max-width: 1024px) {
    .success-main {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom; } }
  @media screen and (max-width: 600px) {
    .success-main {
      background-image: none; } }
`, "",{"version":3,"sources":["webpack://./src/styles/SuccessPage.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,yDAAsD;EACtD,wBAAwB;EACxB,4BAA4B;EAC5B,2BAA2B;EAC3B,gBAAgB,EAAA;EAGhB;IAXJ;MAYM,wBAAwB;MACxB,4BAA4B;MAC5B,2BAA2B,EAAA,EAOhC;EAHG;IAlBJ;MAmBM,sBAAsB,EAAA,EAE3B","sourcesContent":[".success-main {\n    width: 100%;\n    max-width: 1920px;\n    margin: 0 auto;\n    background-image: url(\"../../public/assets/d-val.svg\");\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: bottom;\n    margin-top: 20px;\n  \n    \n    @media screen and (max-width: 1024px) {\n      background-size: contain;\n      background-repeat: no-repeat;\n      background-position: bottom;\n    }\n  \n  \n    @media screen and (max-width: 600px) {\n      background-image: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
