// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin-slow {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.animate-spin-slow {
  animation: spin-slow 8s linear infinite; }
`, "",{"version":3,"sources":["webpack://./src/styles/LoaderSpiner.scss"],"names":[],"mappings":"AAAA;EACI;IAAK,uBAAuB,EAAA;EAC5B;IAAO,yBAAyB,EAAA,EAAA;;AAGpC;EACI,uCAAuC,EAAA","sourcesContent":["@keyframes spin-slow {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n\n.animate-spin-slow {\n    animation: spin-slow 8s linear infinite; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
