// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-main {
  background: linear-gradient(90deg, #c6c6c6 0%, #e4e4e4 34%, #ffffff 71%, #bbbbbb 99%); }
`, "",{"version":3,"sources":["webpack://./src/styles/Summary.scss"],"names":[],"mappings":"AAAA;EACE,qFAMC,EAAA","sourcesContent":[".summary-main {\n  background: linear-gradient(\n    90deg,\n    #c6c6c6 0%,\n    #e4e4e4 34%,\n    #ffffff 71%,\n    #bbbbbb 99%\n  );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
