// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/assets/bg3.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-container {
  width: 100%;
  max-width: 1920px;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat; }
  @media screen and (max-width: 600px) {
    .question-container {
      background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); } }
  .question-container .line {
    background: linear-gradient(90deg, #bd8b2e 22.07%, #e8d476 36.51%, #bf9b3a 49.98%, #e8d370 68.27%, #dfc667 87.52%, #edd97d 118.32%); }
`, "",{"version":3,"sources":["webpack://./src/styles/QuestionSection.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EAEjB,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B,EAAA;EAE5B;IARF;MASI,yDAAoD,EAAA,EAavD;EAtBD;IAYI,mIAQC,EAAA","sourcesContent":[".question-container {\n  width: 100%;\n  max-width: 1920px;\n  // background-image: url(\"../../public/assets/bg3.svg\");\n  background-size: cover;\n  background-position: bottom;\n  background-repeat: no-repeat;\n \n  @media screen and (max-width: 600px) {\n    background-image: url(\"../../public/assets/bg3.svg\");\n  }\n  .line {\n    background: linear-gradient(\n      90deg,\n      #bd8b2e 22.07%,\n      #e8d476 36.51%,\n      #bf9b3a 49.98%,\n      #e8d370 68.27%,\n      #dfc667 87.52%,\n      #edd97d 118.32%\n    );\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
